import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles className="section">
      <p>
        Send us an email at{" "}
        <a href="mailto:bansiwoodmine@gmail.com"> bansiwoodmine@gmail.com</a>
      </p>
    </ContactStyles>
  )
}

export default Contact
